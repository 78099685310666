import { requestApi } from "@/api/core";
import { dynamicDispatcher } from "@/libs/util";

export const request = ({ data, params, store, api }) => {
  return new Promise(resolve => {
    requestApi({
      api: api.url,
      method: api.method,
      params: params,
      data: data
    }).then(res => {
      if (res != false) {
        let rs = dynamicDispatcher(res, store);
        if (rs != false) {
          resolve(rs);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  });
};
