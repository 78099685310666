var signalR = window.signalR;
import Cookies from "js-cookie";

export default {
  state: {
    osrCon: "",
    team: {
      notSeenConversationCount: 0,
    },
    users: [],
  },
  mutations: {
    setOsrCon(state, osrCon) {
      // console.log('osrCon', osrCon);
      state.osrCon = osrCon;
    },
  },
  actions: {
    handleSignalR({ commit }) {
      //console.error('step 3');
      let _hubBaseUrl = "https://judsanal.gosmart.mn/core/";

      return new Promise((resolve) => {
        let osrCon = new signalR.HubConnectionBuilder()
          .withUrl(_hubBaseUrl + "mainHub", {
            accessTokenFactory: () => Cookies.get('token'),
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();
        //console.log('osrcon', osrCon);
        osrCon
          .start()
          .then(function () {
            console.log('setOsrCon');
            commit("setOsrCon", osrCon);

            // osrCon.on("recieveTeamNotification", (teamId) => {
            //   commit(
            //     "setNotSeenCount",
            //     state.team.notSeenConversationCount + 1
            //   );
            // });

            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
  },
};
