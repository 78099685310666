<template>
  <b-container class="mt-4 h-100" fluid>
    <div class="navbar navbar-dark bg-info navbar-expand-lg" toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="#">Санал хураалт</b-navbar-brand>
      <b-navbar-brand class="inff" href="#" style="margin-left: auto" v-if="mode==1">
        Санал хураалтад нийт <b>{{ config.userCount }}</b> хүн оролцохоос <b>{{ config.sanalUgsunToo }}</b> хүн санал өгч <b>{{ config.percent.toFixed(2) }}</b> % тай байна
      </b-navbar-brand>
    </div>
    <b-card v-if="mode == 1">
      <b-row align-v="center">
        <b-col align-self="start" style="text-align: center;">
          <b-img fluid-grow style="max-width: calc(100vh - 120px)" :src="`data:image/png;base64, ${qr}`"></b-img>
        </b-col>
        <b-col :key="keyVal">
          <p class="h3 mb-4">
            {{ question }}
          </p>
          <div v-for="(a, ai) in answers" :key="ai" style="font-size: 36px">
            {{ a.answer }} ({{ a.count }})
            <div style="float: right" class="text-muted">
              {{ a.percent.toFixed(2) }}%
            </div>
            <div class="progress mb-3">
              <div
                :class="`progress-bar ${getBg(a)} `"
                role="progressbar"
                :style="`width: ${a.percent}%`"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <!-- <div style="font-size: 36px">
            Дэмжинэ ({{ yesCount }})
            <div style="float: right" class="text-muted">
              {{ percYes > 0 ? percYes.toFixed(2) : 0 }}%
            </div>
            <div class="progress mb-3">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                :style="`width: ${percYes}%`"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div style="font-size: 36px">
            Дэмжихгүй ({{ noCount }})
            <div style="float: right" class="text-muted">
              {{ percNo > 0 ? percNo.toFixed(2) : 0 }}%
            </div>
            <div class="progress">
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                :style="`width: ${percNo}%`"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div> -->
        </b-col>
      </b-row>
    </b-card>
    <div v-else-if="mode == 2">
      <div>
        <div
          class="h1"
          style="
            width: 80%;
            height: 100px;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 10rem;
            margin: auto;
            text-align: center;
          "
        >
          {{ timerCount }}
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <div
          class="h5"
          style="
            width: 80%;
            height: 100px;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            margin: auto;
            text-align: center;
          "
        >
          {{
            fetched
              ? "Та санал хураалтад бэлэн болсон байна."
              : "Түр хүлээнэ үү..."
          }}
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { request } from "@/api/all";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      config: {
        userCount: null,
        sanalUgsunToo: null,
        percent: null,
      },
      fetched: false,
      keyVal: 0,
      form: {
        email: "",
        password: "",
      },
      mode: 0,
      question: "",
      supportMode: false,
      answers: [],

      // yesCount: null,
      // noCount: null,
      qr: null,
      // percYes: null,
      // percNo: null,

      timerEnabled: false,
      timerCount: 5,
    };
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 1 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          //alert(this.timerEnabled+' '+ value)
          if (this.timerEnabled) this.init();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    let self = this;
    if (
      this.$cookie.get("token") != undefined &&
      this.$cookie.get("token") != null &&
      this.$cookie.get("token") != ""
    ) {
      this.init();

      this.$store.state.signalr.osrCon.on("receiveSanalCheck", (res) => {
        self.answers = res.answers;
        self.config = res.config;
        // self.yesCount = res.yesCount;
        // self.noCount = res.noCount;
        // self.$nextTick(() => {
        //   self.calcPerc();
        // });
      });

      this.$store.state.signalr.osrCon.on("receiveNewQuestion", () => {
        self.mode = 2;
        self.play();
      });
      this.$store.state.signalr.osrCon.on("questionDeleted", () => {
        window.location.href = "/";
      });
    } else {
      this.$router.push({ name: "auth" });
    }
  },
  methods: {
    getBg(a){
      let rv='';
      if(this.supportMode){
        if(a.ord==10){
          rv='bg-success';  
        }else if(a.ord==20){
          rv='bg-danger';  
        }else{
          rv='bg-secondary';  
        }
      }else{
        rv='bg-primary';
      }
      return rv;
    },
    play() {
      this.timerEnabled = true;
    },

    pause() {
      this.timerCount = 5;
      this.timerEnabled = false;
    },
    // calcPerc() {
    //   this.percYes = (this.yesCount * 100) / (this.yesCount + this.noCount);
    //   if (isNaN(parseFloat(this.percYes)) || this.percYes == null) {
    //     this.percYes = 0;
    //   }
    //   this.percNo = (this.noCount * 100) / (this.yesCount + this.noCount);
    //   if (isNaN(parseFloat(this.percNo)) || this.percNo == null) {
    //     this.percNo = 0;
    //   }
    //   this.$nextTick(() => {
    //     this.keyVal++;
    //   });
    // },
    init() {
      this.fetched = false;
      request({
        api: { method: "GET", url: "Question/Active" },
        store: this.$store,
      }).then((res) => {
        console.log(res);
        this.fetched = true;
        if (res != false && res.data.redirectMode == 1) {
          this.config= res.data.config;
          this.question = res.data.question;
          this.supportMode = res.data.supportMode;
          this.qr = res.data.qr;
          this.answers = res.data.answers;
          // this.yesCount = res.data.yesCount;
          // this.noCount = res.data.noCount;
          this.mode = 1;
          this.pause();
          // this.$nextTick(() => {
          //   this.calcPerc();
          // });
        } else if (res != false && res.data.redirectMode == 2) {
          this.$router.push({ name: "list" });
        } else if (res != false && res.data.redirectMode == 3) {
          this.$router.push({ name: "poll" });
        }
      });
    },
  },
};
</script>
<style>
@media print { 
    .navbar {
        display: block !important;
        border-width:0 !important;


    }
    .navbar .inff{
      text-align: right !important;
      float: right;
    }
    .navbar-toggle {
        display:none;
    }
}
</style>
