import Cookies from "js-cookie";
export const TOKEN_KEY = "token";
var CryptoJS = require("crypto-js");
// import { Notification } from "element-ui";

// import "element-ui/lib/theme-chalk/index.css";

export const getToken = () => {
    const token = Cookies.get(TOKEN_KEY);
    if (token) return token;
    else return false;
  };

  
export const dynamicDispatcher = res => {
    var Pass = CryptoJS.enc.Utf8.parse(res.data.substring(0, 22));
    var encryptData = res.data.substring(22, res.data.length);
  
    try {
      var iv = CryptoJS.enc.Hex.parse("e84ad660c4721ae0e84ad660c4721ae0");
      var Salt = CryptoJS.enc.Utf8.parse("u2m9d2dfds1xS!2");
      var key128Bits1000Iterations = CryptoJS.PBKDF2(
        Pass.toString(CryptoJS.enc.Utf8),
        Salt,
        { keySize: 128 / 32, iterations: 1000 }
      );
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(encryptData)
      });
  
      var decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        key128Bits1000Iterations,
        { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 }
      );
      let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      //console.log('data code================ ', data.code);
      if (data.code !== 200) {
        if (data.code === 401) {
          Cookies.remove(TOKEN_KEY);
          //window.location.href = "/";
          //window.location.href = '/#/login'
          // Notice.error({desc:'Нэвтрэх шаардлагатай'})
        } else if (data.code === 204) {
            //alert(data.msg);
            //window.location.href = "/";
        //   Notification.info({
        //     message: data.msg,
        //     title: "Мэдэгдэл",
        //     duration: 6000,
        //     type: "error",
        //     position: "top-right",
        //     offset: 50
        //   });
        } else if (data.code === 201) {
            // this.$bvToast.toast(data.msg, {
            //     title: "Мэдэгдэл",
            //     autoHideDelay: 5000,
            //   });
            alert(data.msg)
            //window.location.href = "/";
        //   Notification.info({
        //     message: data.msg,
        //     title: "Мэдэгдэл",
        //     duration: 3000,
        //     type: "info",
        //     position: "top-right",
        //     offset: 50
        //   });
        } else if (data.code === 202) {
            //alert(data.msg)
            //window.location.href = "/";
        //   Notification.info({
        //     message: data.msg,
        //     title: "Мэдэгдэл",
        //     duration: 6000,
        //     type: "warning",
        //     position: "top-right",
        //     offset: 50
        //   });
        } else if (data.code === 203) {
            //alert(data.msg)
            //window.location.href = "/";
        //   Notification.info({
        //     message: data.msg,
        //     title: "Мэдэгдэл",
        //     duration: 30000,
        //     type: "error",
        //     position: "top-right",
        //     offset: 50
        //   });
        } else if (data.code === 401) {
          //alert(data.msg)
      } else if (data.code === 500) {
            //alert(data.msg)
            //window.location.href = "/";
        //   Notification.info({
        //     message: data.msg,
        //     title: "Мэдэгдэл",
        //     duration: 30000,
        //     type: "error",
        //     position: "top-right",
        //     offset: 50
        //   });
        } else if (data.code === 503) {
            //alert(data.msg)
            //window.location.href = "/";
        //   Notification.info({
        //     message: data.msg,
        //     title: "Мэдэгдэл",
        //     duration: 30000,
        //     type: "error",
        //     position: "top-right",
        //     offset: 50
        //   });
        }
        //   if (data.msg) Notice.error({
        //     title: 'Алдаа',
        //     desc: data.msg,
        //     duration:20
        // });
  
        // this.hideLoading();
        return false;
      } else return data;
    } catch (ex) {
        //alert(ex)
    //   Notification.info({
    //     message: ex,
    //     title: "Мэдэгдэл",
    //     duration: 10000,
    //     type: "warning",
    //     position: "top-right",
    //     offset: 50
    //   });
  
      return false;
    }
  };