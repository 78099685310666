import axios from "@/libs/api.request";
import { getToken } from "@/libs/util";
//import { dynamicDispatcher } from "@/libs/util";

// export const loginApi = ({ user, password, key }) => {
//   return axios.request({
//     url: "Core/LoginApi",
//     params: {
//       user: user,
//       password: password,
//       key: key
//     },
//     method: "post"
//   });
// };

export const requestApi = ({ api, method, data, params }) => {
  //console.log(params);
  return axios.request({
    url: "Core/RequestApi",
    params: params,
    data: {
      api: api,
      method: method,
      token: getToken(),
      data: data
    },
    method: "post"
  });
};
