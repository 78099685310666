import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import {
  getToken
} from "@/libs/util";

Vue.use(VueRouter);
const token = getToken();
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/auth",
    name: "auth",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/list",
    name: "list",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/List.vue"),
  },
  {
    path: "/poll",
    name: "poll",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Poll.vue"),
  },
  {
    path: "/t",
    name: "t",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/T.vue"),
  },
  {
    path: "/u/:v",
    name: "l",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/L.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  //console.error('step 1', to, from, token);
  if (token){
    //console.error('step 1_1');
    store.dispatch("handleSignalR", {}).then(() => {
      //console.error('step 2');
      next();
    })
  }
 else{
  //console.error('step 1_2');
  next();
 }
});
export default router;
